const da_DK = {}

da_DK.strings = {
  addMore: 'TilfÃÂ¸j flere',
  addMoreFiles: 'TilfÃÂ¸j flere filer',
  addingMoreFiles: 'TilfÃÂ¸j flere filer',
  allowAccessDescription: 'For at kunne tage billeder eller video med dit kamera, skal du tillade adgang til dit kamera for denne side.',
  allowAccessTitle: 'Venligst giv adgang til dit kamera',
  authenticateWith: 'Forbind til %{pluginName}',
  authenticateWithTitle: 'Venligst autentificer med %{pluginName} for at vÃÂ¦lge filer',
  back: 'Tilbage',
  browse: 'gennemse',
  browseFiles: 'gennemse',
  cancel: 'Annuller',
  cancelUpload: 'Annuller upload',
  chooseFiles: 'VÃÂ¦lg filer',
  closeModal: 'Luk Modal',
  companionError: 'Forbindelse til Companion fejlede',
  complete: 'Afsluttet',
  connectedToInternet: 'Forbundet til internettet',
  copyLink: 'Kopier link',
  copyLinkToClipboardFallback: 'Kopier URLen forneden',
  copyLinkToClipboardSuccess: 'Link kopieret til udklipsholderen',
  creatingAssembly: 'Forbereder upload...',
  creatingAssemblyFailed: 'Transloadit: Kunne ikke oprette Assembly',
  dashboardTitle: 'Fil Uploader',
  dashboardWindowTitle: 'Fil Uploader Vindue (Tryk escape for at lukke)',
  dataUploadedOfTotal: '%{complete} af %{total}',
  done: 'FÃÂ¦rdig',
  dropHereOr: 'TrÃÂ¦k filer her eller %{browse}',
  dropHint: 'TrÃÂ¦k dine filer her',
  dropPasteBoth: 'TrÃÂ¦k filer her, sÃÂ¦t ind eller %{browse}',
  dropPasteFiles: 'TrÃÂ¦k filer her, sÃÂ¦t ind eller %{browse}',
  dropPasteFolders: 'TrÃÂ¦k filer her, sÃÂ¦t ind eller %{browse}',
  dropPasteImportBoth: 'TrÃÂ¦k filer her, sÃÂ¦t ind, %{browse} eller importer fra',
  dropPasteImportFiles: 'TrÃÂ¦k filer her, sÃÂ¦t ind, %{browse} eller importer fra',
  dropPasteImportFolders: 'TrÃÂ¦k filer her, sÃÂ¦t ind, %{browse} eller importer fra',
  editFile: 'Rediger fil',
  editing: 'Redigerer %{file}',
  emptyFolderAdded: 'Ingen filer blev tilfÃÂ¸jet fra en tom mappe',
  encoding: 'Encoding...',
  enterCorrectUrl: 'Forkert URL: Venligst sÃÂ¸rg for at du indtaster et direkte link til en fil',
  enterUrlToImport: 'Indtast URL for at importerer en fil',
  exceedsSize: 'Denne fil overskrider den maksimale tilladte stÃÂ¸rrelse af %{size}',
  failedToFetch: 'Companion kunne ikke hente denne URL, venligst undersÃÂ¸g om denne er korrekt',
  failedToUpload: 'Fejlede upload af %{file}',
  fileSource: 'Fil kilde: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} af %{smart_count} filer uploaded',
    '1': '%{complete} af %{smart_count} fil uploaded',
    '2': '%{complete} af %{smart_count} filer uploaded',
  },
  filter: 'Filter',
  finishEditingFile: 'FÃÂ¦rddiggÃÂ¸r redigering af fil',
  folderAdded: {
    '0': 'TilfÃÂ¸jet %{smart_count} filer fra %{folder}',
    '1': 'TilfÃÂ¸jet %{smart_count} fil fra %{folder}',
    '2': 'TilfÃÂ¸jet %{smart_count} filer fra %{folder}',
  },
  import: 'Importer',
  importFrom: 'Importer fra %{name}',
  loading: 'Loading...',
  logOut: 'Log ud',
  myDevice: 'Min enhed',
  noFilesFound: 'Du har ingen filer eller mapper her',
  noInternetConnection: 'Ingen Internet forbindelse',
  openFolderNamed: 'ÃÂben mappe %{name}',
  pause: 'Pause',
  pauseUpload: 'Pause upload',
  paused: 'Sat pÃÂ¥ pause',
  poweredBy: 'Drevet af %{uppy}',
  processingXFiles: {
    '0': 'Behandler %{smart_count} filer',
    '1': 'Behandler %{smart_count} fil',
    '2': 'Behandler %{smart_count} filer',
  },
  removeFile: 'Fjern fil',
  resetFilter: 'Nulstil filter',
  resume: 'Genoptag',
  resumeUpload: 'Genoptag upload',
  retry: 'ForsÃÂ¸g igen',
  retryUpload: 'ForsÃÂ¸g upload igen',
  saveChanges: 'Gem ÃÂ¦ndringer',
  selectFileNamed: 'VÃÂ¦lg fil %{name}',
  selectX: {
    '0': 'VÃÂ¦lg %{smart_count}',
    '1': 'VÃÂ¦lg %{smart_count}',
    '2': 'VÃÂ¦lg %{smart_count}',
  },
  smile: 'Smil!',
  startRecording: 'Start video optagelse',
  stopRecording: 'Stop video optagelse',
  takePicture: 'Tag et billede',
  timedOut: 'Upload gÃÂ¥et i stÃÂ¥ for %{seconds} sekunder, afbryder.',
  unselectFileNamed: 'Afmarker filen %{name}',
  upload: 'Upload',
  uploadComplete: 'Upload fÃÂ¦rdig',
  uploadFailed: 'Upload fejlede',
  uploadPaused: 'Upload sat pÃÂ¥ pause',
  uploadXFiles: {
    '0': 'Upload %{smart_count} fil',
    '1': 'Upload %{smart_count} filer',
    '2': 'Upload %{smart_count} filer',
  },
  uploadXNewFiles: {
    '0': 'Upload +%{smart_count} fil',
    '1': 'Upload +%{smart_count} filer',
    '2': 'Upload +%{smart_count} filer',
  },
  uploading: 'Uploader',
  uploadingXFiles: {
    '0': 'Uploader %{smart_count} fil',
    '1': 'Uploader %{smart_count} filer',
    '2': 'Uploader %{smart_count} filer',
  },
  xFilesSelected: {
    '0': '%{smart_count} fil valgt',
    '1': '%{smart_count} filer valgt',
    '2': '%{smart_count} filer valgt',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} flere filer tilfÃÂ¸jet',
    '1': '%{smart_count} flere filer tilfÃÂ¸jet',
    '2': '%{smart_count} flere filer tilfÃÂ¸jet',
  },
  xTimeLeft: '%{time} tilbage',
  youCanOnlyUploadFileTypes: 'Du kan kun uploade: %{types}',
  youCanOnlyUploadX: {
    '0': 'Du kan kun uploade %{smart_count} fil',
    '1': 'Du kan kun uploade %{smart_count} filer',
    '2': 'Du kan kun uploade %{smart_count} filer',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Du skal vÃÂ¦lge mindst %{smart_count} fil',
    '1': 'Du skal vÃÂ¦lge mindst %{smart_count} filer',
    '2': 'Du skal vÃÂ¦lge mindst %{smart_count} filer',
  },
}

da_DK.pluralize = function pluralize (n) {
  if (n === 1) {
    return 0
  }
  return 1
}

if (typeof window !== 'undefined' && typeof window.Uppy !== 'undefined') {
  window.Uppy.locales.da_DK = da_DK
}

module.exports = da_DK
